.App {
  text-align: center;
  color: #162330;
  min-height: 100vh;
}

/*
question: #162330
*/
.App-header {
  background-color: transparent;
  z-index: 30;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  margin: 0px;
  padding: 14px 16px 10px;
  box-sizing: border-box;
  pointer-events: none;
  transition: opacity 0.3s ease 0s;
  opacity: 1;
  text-align: left;
}

.App-h1 {
  font-weight: unset;
  font-size: 24px;
  line-height: 32px;
  color: rgb(22, 35, 48);
}

.App-h2 {
  font-size: 20px;
  line-height: 28px;
  color: rgba(22, 35, 48, 0.7);
  font-weight: unset;
}

.App-button {
  position: relative;
  font-family: inherit;
  line-height: inherit;
  font-weight: 700;
  font-size: 25px;
  cursor: pointer;
  transition-duration: 0.1s;
  transition-property: background-color, color, border-color, opacity, box-shadow;
  transition-timing-function: ease-out;
  outline: none;
  border: 1px solid transparent;
  margin: 0px;
  box-shadow: 0px 3px 12px 0px;
  padding: 8px 18px;
  min-height: 48px;
  background-color: #1B8AFF;/*rgb(22, 35, 48);*/
  color: rgb(255, 255, 255);
  border-radius: 4px;
  -webkit-font-smoothing: antialiased;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
